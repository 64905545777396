.map-wrap {
    position: relative;
    width: 100%;
    height: 350px;
    /* calculate height of the screen minus the heading */
}

.map {
    position: absolute;
    width: 100%;
    height: 100%;
}