.mobileContainer {
    position: relative;
    display: inline-block;
}

.foreground-image {
    position: relative;
    z-index: 2;
    width: 100%;
}

.background-image {
    border-radius: 30px;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 97%;
    object-fit: cover;
    z-index: 1;
    filter: brightness(45%);
}


.foreground-content {
    position: absolute;
    top: 26px;
    left: -1px;
    width: 100%;
    height: 100%;
    z-index: 9990;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.top-content {
    flex-direction: row;
    justify-content: space-between;
    width: 93%;
    border-radius: 15px 15px 0 0;

    font-size: 0.7em;
    font-weight: 300;
    padding-inline: 10px;
    padding-top: 5px;
    background-color: rgba(0, 0, 0, 0.8);
}

.wppAbout-content {
    position: absolute;
    margin-top: 270px;
    margin-inline: 15px;
    padding-block: 10px;
    padding-inline: 10px;
    font-size: 1.0em;
    font-weight: 200;
    background-color: rgba(0,128,105,0.3);
    border-radius: 10px;
    /* font-style: italic; */
}


.footer-content1 {
    padding-top: 250px;
    width: 100%;
    display: flex;
    padding-inline: 40px;
    flex-direction: row;
    justify-content: space-between;
}

.footer-content2 {
    padding-top: 40px;
    width: 100%;
    display: flex;
    padding-inline: 40px;
    flex-direction: row;
    justify-content: space-between;
}



.iconLabel {
    position: absolute;
    left: -3px;
    font-size: 0.6em;
    font-weight: 300;
    padding-top: 2px;
}

.modal-backdrop {
    background-color: transparent; 
    opacity: 1; 
}

